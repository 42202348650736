<template>
	<div class="animated fadeIn">
		<b-alert
			show
			variant="secondary">
			<div class="row">
				<div class="col">
					<search
						v-model="selectedUser"
						:label-text="translate('autocomplete_label')"
						:endpoint="endpoint"
						:empty-result-message="translate('data_not_found')"
						:default-params="{role:'distributor', tree:'unilevel'}"
						:placeholder="'autocomplete_placeholder'"
						class="mb-3"
						q="label"
						return-key="id" />
					<b-button
						variant="primary"
						@click="changeUser(selectedUser)">
						{{ translate('search') }}
					</b-button>
					<b-button
						variant="secondary"
						class="ml-1"
						@click="changeUser()">
						{{ translate('clear') }}
					</b-button>
				</div>
			</div>
		</b-alert>
		<div
			v-if="hasData"
			class="card">
			<div class="card-body">
				<b-tabs v-model="tabIndex">
					<b-tab
						v-for="(tabInfo, index) in tabs"
						:key="index">
						<template slot="title">
							<a
								:class="$route.query.placement === tabInfo.placement ? 'nav-link ' + $router.options.linkActiveClass : 'text-gray-dark'"
								class="list-group-item btn text-left"
								@click="setPlacement(tabInfo.placement)">
								{{ translate(tabInfo.translate_key) }} ({{ totals(tabInfo.placement) }})
							</a>
						</template>
						<template
							v-if="tabIndex === index"
							class="p-0">
							<router-view :class="windowWidth === 'xs' ? '' : 'p-5'" />
						</template>
					</b-tab>
				</b-tabs>
				<div v-if="!loading && hasData">
					<!-- Account types -->
					<div class="row">
						<div class=" col-12 col-sm-10 col-md-8 ml-auto text-right mt-2 mb-3">
							<span
								v-for="account in accountStatus"
								:key="account.statusCode"
								:class="['xs','sm'].includes(windowWidth) ? 'col-12' : ''"
								class="d-inline-block">
								<div
									v-if="isAdminOrCorporate || distributorHiddenStatuses[account.statusCode] === undefined"
									class="ml-4">
									<template v-if="account.separator === true">
										<span class="d-none d-sm-block ">|</span>
									</template>
									<i
										v-else
										:class="`${ account.icon } ${account.color}`"
										class="mr-1" /> {{ translate(account.statusCode) }}
								</div>
							</span>
						</div>
					</div>
					<!-- Model -->
					<div
						class="row"
						style="overflow: auto;">
						<div class="col">
							<!-- Headers -->
							<li
								class="member"
								:style="`display: table; width:100%`">
								<div class="p-2 border-bottom border-secondary">
									<!-- Row container -->
									<div class="row flex-nowrap justify-content-between">
										<!-- ID/Username -->
										<div class="col my-auto font-weight-bold">
											<div class="row flex-nowrap">
												<div class="col ml-4">
													<div class="row flex-nowrap align-items-end h-100">
														<div class="col-auto px-0">
															{{ translate('id_username') }}
														</div>
													</div>
												</div>
												<div class="col ml-3">
													<div class="d-flex justify-content-end pt-2">
														<div class="buttons-width text-center">
															{{ translate('highest_rank') }}
														</div>
														<div class="buttons-width text-center">
															{{ translate('rank_progress', {week: currentWeek}) }}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<template v-for="item in unilevelStructure">
									<child
										v-for="model in item"
										:id="'listItem'"
										:key="model.distributor_id"
										:model="model"
										:first-level="true"
										class="item"
										@showModal="showModal" />
								</template>
							</li>
						</div>
					</div>
				</div>
				<div v-if="loading">
					<is-loading
						:loading-label="translate('loading')"
						:no-data-label="translate('data_not_found')"
						:loading="loading"
						:has-data="hasData"
						class="mt-5" />
				</div>
			</div>
		</div>
		<b-modal
			id="rankProgress"
			ref="rankProgress"
			:title="`${translate('user_id')}: ${progressId} / ${translate('username')}: ${progressUsername}`"
			:ok-title="translate('close')"
			title-tag="h5"
			header-bg-variant="dark"
			header-text-variant="light"
			size="lg"
			centered
			ok-only>
			<div v-if="progressId">
				<rank-progress :user-id="progressId" />
			</div>
		</b-modal>
	</div>
</template>
<script>
import Vue from 'vue';
import UnilevelTreeItem from '@/views/Network/components/UnilevelTreeItem';
import WindowSize from '@/mixins/WindowSizes';
import UnilevelTree from '@/util/UnilevelTree';
import { COLORS as accountStatus, DISTRIBUTOR_HIDDEN_STATUSES } from '@/settings/Statuses';
import { SEARCH_USERS } from '@/config/endpoint';
import { Alerts, Grids, UnilevelRanks } from '@/translations';
import RankProgress from '@/views/Dashboard/components/RankProgress';
import TreeMixin from './mixins/Tree';
import { admin, corporate } from '@/settings/Roles';

const { endpoint } = SEARCH_USERS;

Vue.component('child', UnilevelTreeItem);

export default {
	name: 'UnilevelTree',
	messages: [Alerts, Grids, UnilevelRanks],
	components: { RankProgress },
	mixins: [WindowSize, TreeMixin],
	data() {
		return {
			selectedUser: '',
			tree: {},
			endpoint,
			myUnilevel: new UnilevelTree(),
			myUnilevelMetadata: new UnilevelTree(),
			accountStatus,
			distributorHiddenStatuses: DISTRIBUTOR_HIDDEN_STATUSES,
			progressId: 0,
			progressUsername: '',
			tabIndex: 0,
			admin,
			tabs: [
				{
					placement: 'all',
					translate_key: 'all',
				},
				{
					placement: 'left',
					translate_key: 'left',
				},
				{
					placement: 'right',
					translate_key: 'right',
				},
			],
		};
	},
	computed: {
		hasData() {
			return !!Object.keys(this.unilevel).length;
		},
		loading() {
			return !!this.myUnilevel.data.loading;
		},
		unilevel() {
			try {
				const tree = this.myUnilevel.data.response.data.data.attributes;
				if (tree) {
					return tree;
				}
				return {};
			} catch (error) {
				return {};
			}
		},
		unilevelStructure() {
			const structure = {};
			if (this.hasData) {
				structure[this.unilevel[0].username] = this.unilevel;
			}
			return structure;
		},
		username() {
			try {
				return this.myUnilevelMetadata.data.response.data.response.username;
			} catch (error) {
				return this.$user.details().type;
			}
		},
		currentWeek() {
			try {
				return this.myUnilevelMetadata.data.response.data.response.current_week;
			} catch (error) {
				return {};
			}
		},
		isAdminOrCorporate() {
			return this.admin.includes(this.$user.details().type);
		},
		isCountryCorporate() {
			const isCorporate = this.$user.details().type === corporate;
			const hasCountry = typeof this.$user.details().country !== 'undefined';

			return isCorporate && hasCountry;
		},
	},
	mounted() {
		const { query } = this.$route;
		const loadData = this.isCountryCorporate ? typeof query.id !== 'undefined' : true;
		if (loadData) {
			this.initializeTabs();
			this.loadTree();
		}
	},
	methods: {
		loadTree() {
			const { query } = this.$route;
			this.myUnilevel.getTree(query);
			this.myUnilevelMetadata.getMetadata(query);
		},
		initializeTabs() {
			const hasValidPlacement = ['all', 'left', 'right'].includes(this.$route.query.placement);
			if (!hasValidPlacement) this.$route.query.placement = 'all';
			const { placement } = this.$route.query;
			this.tabIndex = this.tabs.findIndex((item) => item.placement === placement);
			this.updateUrl(placement, this.$route.query.id);
		},
		setPlacement(placement) {
			this.$route.query.placement = placement;
			this.loadTree();
			this.updateUrl(placement, this.$route.query.id);
		},
		updateUrl(placement, id) {
			let currentUrl = (window.location.href).split('?')[0];
			currentUrl = id ? `${currentUrl}?id=${id}&placement=${placement}` : `${currentUrl}?placement=${placement}`;
			window.history.pushState({ path: currentUrl }, '', currentUrl);
		},
		changeUser(id) {
			let userId = parseInt(id, 10);
			if (!Number.isNaN(userId)) {
				userId = { id: userId };
			}
			this.$router.replace({ name: 'UnilevelTree', query: userId });
		},
		showModal(userId, username) {
			this.progressId = userId;
			this.progressUsername = username;
			this.$refs.rankProgress.show();
		},
		totals(key) {
			try {
				return this.myUnilevelMetadata.data.response.data.response[`total_${key}`];
			} catch (error) {
				return 0;
			}
		},
	},
};
</script>

<style>
	.member{
		list-style: none;
	}
	.buttons-width{
		width: 165px;
	}
</style>
