import { COLORS } from '@/settings/Statuses';
import { PLACEMENTS, TREE_TYPES } from '@/settings/Tree';
import BinaryTree from '@/util/BinaryTree';

export default {
	data() {
		return {
			alert: new this.$Alert(),
			moveNodeLeg: new BinaryTree(),
		};
	},
	props: {
		treeType: {
			type: String,
			default: null,
			validator: (value) => !value || Object.values(TREE_TYPES).includes(value),
		},
	},
	mounted() {
		this.$parent.titleParam = { user: this.username };
	},
	watch: {
		username: {
			handler(user) {
				if (this.treeType === TREE_TYPES.BINARY) {
					this.$parent.titleKey = user ? 'BinaryTree' : 'BinaryTreeAlt';
				}
				this.$parent.titleParam = { user };
			},
			immediate: true,
		},
	},
	methods: {
		findColor(status) {
			const accountType = COLORS.find((account) => account.statusCode === status);
			return `${accountType.icon} ${accountType.color}`;
		},
		changeUser(id) {
			const userId = parseInt(id, 10);
			const { name, query, params } = this.$route;
			delete params.userId;
			if (!Number.isNaN(userId)) {
				params.userId = userId;
			}
			this.$router.replace({ name, params, query }).catch(() => {});
		},
		childItem(position) {
			const pos = PLACEMENTS[position.toLowerCase()];
			if (!this.tree.children || typeof pos === 'undefined') {
				return false;
			}
			const [response] = this.tree.children.filter((item) => ((item.binary_placement === pos) ? item : false));
			return response;
		},
		brotherItemId(position) {
			const pos = PLACEMENTS[position.toLowerCase()];
			if (!this.tree.children || typeof pos === 'undefined') {
				return null;
			}
			const [response] = this.tree.children.filter((item) => ((item.binary_placement === pos) ? item : false));
			if (response && response.available_position_movement) {
				return response.distributor_id;
			}
			return null;
		},
		showAvailableMovementPosition(distributorId) {
			console.log(distributorId);
		},
		registerInPosition(payload) {
			const { parent, placement, spillover } = payload;
			if (typeof parent === 'undefined' || typeof placement === 'undefined') {
				return null;
			}
			this.$emit('registerInPosition', { parent, placement, spillover });
			return null;
		},
		moveNode(userId, username, treePosition, position, parentId, parentName) {
			const currentPosition = this.translate(treePosition);
			const newPosition = this.translate(position);
			const trans = {
				title: this.translate('move_node_title'),
				text: this.translate('password'),
			};
			const options = {
				buttonText: this.translate('confirm'),
				cancelButtonText: this.translate('cancel'),
				message: this.translate('move_node_body', {
					id: userId, username, current_position: currentPosition, new_position: newPosition, parent_info: parentName,
				}),
				emptyPasswordMessage: this.translate('password_is_required'),
			};

			this.alert.confirmationWithPassword(trans.title, trans.text, options).then((password) => {
				this.moveNodeLeg.binaryTreeMoveNode({ id: userId, position, password: password.value }).then((response) => {
					this.alert.toast('success', response.response.message);
					this.$parent.getDataFiltered(Number(this.childRows), parentId);
				}).catch((err) => {
					Object.values(err).forEach((messages) => {
						messages.forEach((message) => {
							this.alert.toast('error', message);
						});
					});
				});
			}).catch(() => {});
		},
		openLegMovementInfo() {
			const style = getComputedStyle(document.body);
			const config = {
				config: {
					allowEscapeKey: false,
					allowOutsideClick: false,
					showLoaderOnConfirm: true,
					confirmButtonColor: style.getPropertyValue('--primary'),
					confirmButtonText: this.translate('leg_movement_terms_button'),
					showCancelButton: false,
					isHtml: true,
					reverseButtons: true,
				},
			};

			this.alert.confirmation(
				this.translate('leg_movement_terms_title'),
				this.translate('leg_movement_terms_body'),
				config,
				true,
			);
		},
		parentName(tree) {
			if (tree.is_spillover) {
				return this.translate('spillover').toUpperCase();
			} if (tree.is_unauthorized) {
				return this.translate('unauthorized').toUpperCase();
			}
			return (`${tree.distributor_id } - ${ tree.username}`);
		},
	},
};
