import {
	GET_BINARY_TREE,
	GET_OUTER_NODES,
	GET_BINARY_TREE_VOLUME,
	GET_BINARY_TREE_INFORMATION,
	BINARY_TREE_MOVE_NODE,
} from '@/config/endpoint';
import Req from './AxiosRequest';

class BinaryTree {
	constructor() {
		this.data = new Req();
		this.volumeData = new Req();
		this.outerData = new Req();
		this.errors = this.data.errors;
	}

	clear() {
		this.data.clear();
	}

	getTree(options) {
		const { method, endpoint } = GET_BINARY_TREE;
		const queryParams = Req.setFilters(options);
		return this.data[method](endpoint(queryParams)).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getBinaryTreeVolume(options) {
		const { method, endpoint } = GET_BINARY_TREE_VOLUME;
		const queryParams = Req.setFilters(options);
		return this.volumeData[method](endpoint(queryParams)).then((response) => response).catch((error) => {
			this.volumeData.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getBinaryTreeInformation(options) {
		const { method, endpoint } = GET_BINARY_TREE_INFORMATION;
		const queryParams = Req.setFilters(options);
		return this.data[method](endpoint(queryParams)).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	binaryTreeMoveNode(payload) {
		const { method, endpoint } = BINARY_TREE_MOVE_NODE;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.errors.record(error.errors);
			return Promise.reject(error.errors);
		});
	}

	getOuterNodes(options) {
		const { method, endpoint } = GET_OUTER_NODES;
		const { id } = options;
		return this.outerData[method](endpoint(id)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default BinaryTree;
